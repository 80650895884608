import { Link } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import routes from "../routes";

/*
Keep flipping between
"£20 per month" and "$20 a month" and "$20/month"
so this var is just an easy switch for that
*/
const perString = " a ";

enum BillingFrequency {
  Monthly = "monthly",
  Yearly = "yearly",
}

const plans: PricingPlan[] = [
  {
    name: "Basic",
    explainer: `A simple way<br /> to get started`,
    pricesUsd: {
      [BillingFrequency.Monthly]: 0,
      [BillingFrequency.Yearly]: 0,
    },
    pricePeriod: "forever!",
    c2aText: "Get started",
    c2aHref: routes.createAccount,
    featureIntro: "Access to:",
    featureList: [
      "Up to 10 PDFs a day",
      "Up to 100 PDFs a month",
      "3 templates",
    ],
  },
  {
    name: "Plus",
    explainer: "For small teams<br /> & low-traffic apps",
    pricesUsd: {
      [BillingFrequency.Monthly]: 35,
      [BillingFrequency.Yearly]: 28,
    },
    pricePeriod: "/month",
    c2aText: "Try it free",
    c2aHref: routes.createAccount,
    featureIntro: "Everything in Basic, and:",
    featureList: [
      `Up to 30 PDFs${perString}day`,
      `Up to 300 PDFs${perString}month`,
      `5 templates`,
    ],
  },
  {
    name: "Business",
    explainer: "For growing<br/> companies & apps",
    pricesUsd: {
      [BillingFrequency.Monthly]: 99,
      [BillingFrequency.Yearly]: 79,
    },
    pricePeriod: "/month",
    c2aText: "Try it free",
    c2aHref: routes.createAccount,
    priceIsStartingAt: true,
    mostPopular: true,
    featureIntro: "Everything in Plus, and:",
    featureList: [
      `150 - 750 PDFs${perString}day`,
      `Unlimited templates`,
      `Removal of the DocuZen footer branding`,
      `Multi-factor auth`,
    ],
  },
  {
    name: "Scale",
    explainer: "For large companies<br /> and high traffic apps",
    pricesUsd: {
      [BillingFrequency.Monthly]: 499,
      [BillingFrequency.Yearly]: 399,
    },
    pricePeriod: "/month",
    c2aText: "Talk to sales",
    c2aHref: routes.contact,
    priceIsStartingAt: true,
    featureIntro: "Everything in Business, and:",
    alternativeBtn: true,
    featureList: [
      `1000+ PDFs${perString}day`,
      `Dedicated customer success manager`,
      `SAML single sign-on`,
      `Uptime SLAs`,
    ],
  },
];

const PricingPlan: React.FC<{
  plan: PricingPlan;
  yearlyBilling: boolean;
}> = ({ plan, yearlyBilling }) => {
  const {
    name,
    explainer,
    pricesUsd,
    pricePeriod,
    mostPopular,
    priceIsStartingAt,
    c2aText,
    c2aHref,
    featureIntro,
    featureList,
    alternativeBtn,
  } = plan;

  return (
    <>
      <div className="pricing-plan">
        <div className="pricing-plan__header">
          <h2>{name}</h2>
          <div className="pricing-plan__explainer">
            <p dangerouslySetInnerHTML={{ __html: explainer }}>{}</p>
          </div>
          {mostPopular && (
            <div className="pricing-plan__most-popular">Most popular!</div>
          )}
          <div className="pricing-plan__price">
            {priceIsStartingAt && (
              <div className="pricing-plan__price__starting-at">
                Starting at:
              </div>
            )}
            <p className="pricing-plan__price__amount">
              <span className="pricing-plan__price__currency">$</span>
              {yearlyBilling ? pricesUsd.yearly : pricesUsd.monthly}
              <span className="pricing-plan__price__pence">.00</span>
            </p>
            <div className="pricing-plan__price__per">{pricePeriod}</div>
            <Link
              to={c2aHref}
              className={`btn btn-primary pricing-plan__btn ${
                alternativeBtn ? "btn-tertiary" : ""
              }`}
            >
              {c2aText}
            </Link>
          </div>
        </div>
        <div className="pricing-plan__feature-set">
          <h3>{featureIntro}</h3>
          <ul className="pricing-plan__feature-list">
            {featureList.map(x => (
              <li>{x}</li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

const PricingPage = () => {
  const [yearlyBilling, setYearlyBilling] = useState(true);

  return (
    <Layout wrapperClass="pricing-page">
      <Seo title="Pricing" />
      <div className="container">
        <h1 className="text-center">Pricing</h1>
        <div className="pricing-plans my-4">
          <div className="pricing-plans__intro text-center mb-5 mt-4">
            No job too big or small
          </div>
          <div className="pricing-plans__period-toggle mb-5">
            <button onClick={() => setYearlyBilling(false)}>
              Billed Monthly
            </button>
            <div className="toggle-switch">
              <input
                type="checkbox"
                id="switch"
                checked={yearlyBilling}
                onChange={() => {
                  setYearlyBilling(!yearlyBilling);
                }}
              />
              <label htmlFor="switch">Toggle</label>
            </div>
            <button onClick={() => setYearlyBilling(true)}>
              Billed Yearly
            </button>
          </div>
          <ul>
            {plans.map(plan => (
              <li>
                <PricingPlan plan={plan} yearlyBilling={yearlyBilling} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPage;

interface PricingPlan {
  name: string;
  explainer: string;
  pricesUsd: {
    [BillingFrequency.Monthly]: number;
    [BillingFrequency.Yearly]: number;
  };
  pricePeriod: string;
  priceIsStartingAt?: boolean;
  mostPopular?: boolean;
  c2aText: string;
  c2aHref: string;
  featureIntro: string;
  featureList: string[];
  alternativeBtn?: boolean;
}
